import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import JobRoll from '../../components/JobRoll'

export default class JobIndexPage extends React.Component {
  render() {
    
  return (
      <Layout>
        <Helmet>
            <title>WesData | Careers</title>
        </Helmet>
        <section className="section">
          <div className="container">
            <div className="content">
            <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url('/img/careers-astro.jpg')`,
                  backgroundPosition: "center"  
                }}
              >
                <h1
                  className="has-text-weight-bold is-size-1"
                  style={{
                    boxShadow: '0.5rem 0 0 #333399, -0.5rem 0 0 #333399',
                    backgroundColor: '#333399',
                    color: 'white',
                    padding: '1rem',
                  }}
                >
                  Careers
                </h1>
              </div>
            </div>
              <JobRoll />
            </div>
        </section>
      </Layout>
    )
  }
}
